<template>
  <b-card no-body class="mb-0">
    <app-data-table
      ref="refAttributListTable"
      :items="fetchAttributes"
      :fields="tableColumns"
      :top-table="{
        showAdd: $can('OPERATOR_ATTRIBUTE_ADD'),
        showSearch:$can('OPERATOR_ATTRIBUTE_VIEW'),
        searchText: params.categoryId,
        searchFieldType: 'number',
        searchFilterOptions,
      }"
      :actions-table="{
        showEdit: $can('OPERATOR_ATTRIBUTE_EDIT'),
        showDelete: $can('OPERATOR_ATTRIBUTE_DELETE'),
      }"
      :bottom-table="{ totalCount: totalAttributs }"
      @table-add="$router.push({ name: 'attribute-add' })"
      @table-search-text="params.categoryId = $event"
      @edit="$router.push({ name: 'attribute-edit', params: { attribute_id: $event.item.id } })"
      @delete="deleteAttibute($event.item)"
      @pagination="params.page = $event"
    >
      <!-- Column: Category IDS -->
      <template #cell(id)="data">
        <ul>
          <li v-for="category in data.item.categories" :key="category.id">{{ category.name|trans }} (#{{ category.id }})</li>
        </ul>
      </template>

      <!-- Column: Attribut -->
      <template #cell(name)="data">
        <b-media vertical-align="center" :to="{ name: 'attribute-edit', params: { attribute_id: data.item.id } }">
          <div class="font-weight-bold d-block text-nowrap">
            {{ data.item.name | trans }}
          </div>
        </b-media>
      </template>

      <!-- Column: Role -->
      <template #cell(role)="data">
        <div class="text-nowrap">
          <font-awesome-icon :icon="resolveRoleIcon(data.item.role)" class="mr-50" :class="`text-${resolveRoleVariant(data.item.role)}`" />
          <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
        </div>
      </template>

      <!-- Column: Filterable -->
      <template #cell(filterable)="data">
        <b-form-checkbox disabled :checked="data.item.filterable" name="check-button" switch inline />
      </template>

      <!-- Column: Required -->
      <template #cell(required)="data">
        <b-form-checkbox disabled :checked="data.item.required" name="check-button" switch inline />
      </template>

      <!-- Column: Type -->
      <template #cell(type)="data">
        <b-badge>
          {{ data.item.type }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data" style="min-width: 10rem">
        <!--EDIT-->
        <b-button
          v-ripple.400
          :disabled="!$can('OPERATOR_ATTRIBUTE_EDIT')"
          variant="flat-primary"
          class="btn-icon mr-50"
          size="sm"
          :to="{ name: 'attribute-edit', params: { attribute_id: data.item.id } }"
        >
          <feather-icon v-b-tooltip.hover.bottom="$t('action.edit')" icon="Edit2Icon" size="20" />
        </b-button>

        <!--DELETE-->
        <b-button
          v-ripple.400
          :disabled="!$can('OPERATOR_ATTRIBUTE_DELETE')"
          variant="flat-danger"
          class="btn-icon mr-50"
          size="sm"
          @click="deleteAttibute(data.item)"
        >
          <feather-icon v-b-tooltip.hover.bottom="$t('action.delete')" icon="TrashIcon" size="18" />
        </b-button>
      </template>
    </app-data-table>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import UIHelper from '@/helpers/ui'

import AppDataTable from '@/components/AppDataTable.vue'
import { deleteAttributeRequest, fetchAttributesRequest } from '@/request/globalApi/requests/attributeRequests'

export default {
  name: 'AttributeList',

  components: {
    AppDataTable,
  },

  mixins: [UIHelper],
  data() {
    return {
      searchFilterOptions: [
        { text: this.$t('attribute.filter.category_id'), value: 'categoryId' },
      ],
      tableColumns: [
        { key: 'name', sortable: true },
        { key: 'id', label: this.$t('attribute.attached_categories') },
        { key: 'filterable' },
        { key: 'required', sortable: true },
        { key: 'type', sortable: true },
      ],
      params: {
        numberOfItemsPerPage: 10,
        page: 1,
        categoryId: null,
        filterable: null,
      },
      totalAttributs: 0,
    }
  },
  computed: {
    ...mapState('appConfig', ['lang']),
    allFilters() {
      return `${this.params.numberOfItemsPerPage}|${this.params.page}|${this.params.categoryId}|${this.params.filterable}|`
    },
  },
  watch: {
    allFilters: {
      handler() {
        this.refetchData()
      },
      deep: true,
    },
  },
  methods: {
    fetchAttributes(ctx, callback) {
      fetchAttributesRequest(this.params).then(response => {
        const { attributes, totalItems } = response.data
        this.totalAttributs = totalItems
        callback(attributes)
      }).catch(() => {
        // callback() has to run even for 400 error
        callback()
      })
    },
    deleteAttibute(attribute) {
      deleteAttributeRequest(attribute.id, attribute.name[this.lang]).then(() => {
        this.refetchData()
      })
    },
    refetchData() {
      this.$refs.refAttributListTable.refresh()
    },
  },
}
</script>
