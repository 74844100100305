import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/attribute'

export const fetchAttributesRequest = queryParams => globalApi.get(END_POINT, { params: queryParams })

export const fetchAttributeRequest = id => globalApi.get(`${END_POINT}/${id}`)

export const postAttributeRequest = (attribute, message) => globalApi.post(END_POINT, attribute, { message })

export const patchAttributeRequest = (attribute, message) => globalApi.patch(`${END_POINT}/${attribute.id}`, attribute, { message })

export const deleteAttributeRequest = (id, message) => fireDeleteAlert().then(isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${id}`, { message }))
